import { Geocoder } from "@mapbox/search-js-react";
import { useState } from 'react';
import mapboxgl from "mapbox-gl";
const SearchBar = () => {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <Geocoder
        accessToken={'pk.eyJ1IjoidG9wb2wiLCJhIjoiY2lndjM4eDNxMDA0M3Zma3JiOGRmcGNyOSJ9.tPBrXFyMAspRCTjyVKmx8A'}
        map={window.map}
        mapboxgl={mapboxgl}
        value={inputValue}
        onChange={(d) => {
          setInputValue(d);
        }}
        marker
      />
    </>
  );
}

export default SearchBar;