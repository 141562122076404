import React from 'react';
import styled from 'styled-components';
import LogoIcon from './img/logo.jpeg';

const Contacts2 = () => {
  return (
    <StyledContacts>
      <a className="logo" href="##">
        <img src={LogoIcon} alt="logo" width="62" height="62" />
      </a>
    </StyledContacts>
  );
}

const StyledContacts = styled.div`
  position: absolute;
  left: 30px;
  bottom: 30px;  
  z-index: 1000;

  img {
    width: 100%;
    height: 40px;
  }

  @media screen and (max-width: 720px) {
    left: 20px;
    bottom: 20px;  
  }

  @media screen and (max-width: 540px) {
    left: 50%;
    transform: translateX(50%);
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

`;

export default Contacts2;
