import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Calculate from '../img/calculate.png';
import Home from '../img/home.png';
import Tree from '../img/tree.png';
import Polygon from '../img/polygon.png';
import Bin from '../img/bin.png';
import Close from '../img/close.svg';
import Plus from '../img/plus.svg';
import Minus from '../img/minus.svg';
import Up from '../img/arrow_up.svg';
import useConditionalEffect from '../hooks/useConditionalEffect';
import { registerDrawHandler, clearLayers, clearBufferLayers, calculatePointsInZones, vegetationAreas, addBuffer } from '../utils/utils';

const Satellite = ({ drawModeRef, isSatellite, drawMode, setDrawMode, setResult, whichMode, setWichMode, setCalculatedResult, calculatedHandler }) => {

  const [bufferDistances, setBufferDistances] = useState([1.5, 9, 30]);
  const [polygon, setPolygon] = useState(null);
  const [expanded, setExpanded] = useState(false);
  useConditionalEffect(() =>
    registerDrawHandler(window.map, window.draw, setResult, setDrawMode, whichMode, bufferDistances, setPolygon, setExpanded),
    [drawMode[whichMode]]
  );

  useEffect(() => {
    if (polygon) {
      clearBufferLayers();
      bufferDistances.forEach((distance, index) => {
        addBuffer(polygon, distance, index, window.map);
      });
    }
  }, [bufferDistances, polygon]);

  const handlePlus = (index) => {
    const newBufferDistances = [...bufferDistances];
    newBufferDistances[index] += 1;
    setBufferDistances(newBufferDistances);
  };
  const handleMinus = (index) => {
    const newBufferDistances = [...bufferDistances];
    newBufferDistances[index] -= 1;
    setBufferDistances(newBufferDistances);
  };

  const handleExpanded = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const toggleDrawMode = () => {
    calculatedHandler(false);
    setCalculatedResult(0);
    setWichMode('area');
    setDrawMode(prev => ({
      ...prev,
      area: prev.area === 'simple_select' ? 'draw_polygon' : 'simple_select',
      home: 'simple_select',
      point: 'simple_select',
    }));
    if (drawModeRef.current && drawMode.area === 'simple_select') {
      drawModeRef.current('simple_select');
      drawModeRef.current('draw_polygon');
    } else {
      drawModeRef.current('simple_select');
    }
  };
  const toggleDrawModeHome = () => {
    calculatedHandler(false);
    setWichMode('home');
    setDrawMode(prev => ({
      ...prev,
      home: prev.home === 'simple_select' ? 'draw_polygon' : 'simple_select',
      area: 'simple_select',
      point: 'simple_select',
    }));
    if (drawModeRef.current && drawMode.home === 'simple_select') {
      drawModeRef.current('simple_select');
      drawModeRef.current('draw_polygon');
    } else {
      drawModeRef.current('simple_select');
    }
  };
  const toggleDrawModePoint = () => {
    setCalculatedResult(0);
    setWichMode('point');
    setDrawMode(prev => ({
      ...prev,
      point: prev.point === 'simple_select' ? 'draw_point' : 'simple_select',
      area: 'simple_select',
      home: 'simple_select',
    }));
    if (drawModeRef.current && drawMode.point === 'simple_select') {
      drawModeRef.current('simple_select');
      drawModeRef.current('draw_point');
    } else {
      drawModeRef.current('simple_select');
    }
  };

  const clearHandler = () => {
    clearLayers();
    clearBufferLayers();
    setDrawMode(prev => ({
      ...prev,
      area: 'simple_select',
    }));
    setResult(null);
    setCalculatedResult(0);
    setPolygon(null);
    window.draw.changeMode('simple_select');
    window.draw.deleteAll();
  };
  return (
    <>
      <SatelliteContainer>
        {isSatellite && (
          <div className="satellite-controls">
            <div className='satellite-buttons'>
              <button
                onClick={toggleDrawMode}
                className={drawMode.area === 'draw_polygon' ? 'satellite-controls-button active' : 'satellite-controls-button'}
              >
                <img className='button-img' src={Polygon} alt="polygon" /> Draw Lot Perimeter 
              </button>
              <button
                onClick={toggleDrawModeHome}
                className={drawMode.home === 'draw_polygon' ? 'satellite-controls-button active' : 'satellite-controls-button'}
              >
                <img className='button-img' src={Home} alt="home" /> 
                Draw Asset Contour 
                <div className="button-expanded" onClick={(e) => handleExpanded(e)}>
                  <img src={Up} alt="Up" height={10} width={10} style={{transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)'}} />
                </div>
              </button>
              {expanded && (
                <div className='buffer-container'>
                  {bufferDistances.map((distance, index) => (
                    <div key={index} className='buffer-item'>
                      <label>{index + 1} -</label>
                      <div className='buffer-value-btns'>
                        <button className='buffer-value-btn buffer-value-minus' onClick={() => handleMinus(index)} >
                          <img src={Minus} alt="Minus" height={10} width={10} />
                        </button>
                        <input
                          className='buffer-input'
                          type="number"
                          value={distance === 0 ? '' : distance}
                          onChange={(e) => {
                            const newDistances = [...bufferDistances];
                            newDistances[index] = parseFloat(e.target.value) || 0;
                            setBufferDistances(newDistances);
                          }}
                        />
                        <button className='buffer-value-btn buffer-value-plus' onClick={() => handlePlus(index)} >
                          <img src={Plus} alt="Plus"height={10} width={10} />
                        </button>
                      </div>
                      <button
                        className={bufferDistances.length > 3 ? "remove-buffer-button" : "remove-buffer-button disabled"}
                        disabled={bufferDistances.length === 3}
                        onClick={() => {
                          const newDistances = bufferDistances.filter((_, i) => i !== index);
                          setBufferDistances(newDistances);
                        }}
                      >
                        <img src={Close} alt="Delete" height={10} width={10} />
                      </button>
                    </div>
                  ))}
                  {bufferDistances.length < 10 && (
                    <button className='buffer-add' onClick={() => setBufferDistances([...bufferDistances, bufferDistances[bufferDistances.length - 1]+10])}>
                      <span>+</span> Add Buffer
                    </button>
                  )}
                </div>
              )}
              <button
                onClick={toggleDrawModePoint}
                className={drawMode.point === 'draw_point' ? 'satellite-controls-button active' : 'satellite-controls-button'}
              >
                <img className='button-img' src={Tree} alt="tree" /> Add Risk Driver 
              </button>
          
              <button onClick={clearHandler} className="satellite-controls-button">
                <img className='button-img' src={Bin} alt="bin" /> Clear 
              </button>
              <button 
                onClick={() => {
                  const treePointsValue = calculatePointsInZones(window.map, window.draw);
                  setCalculatedResult(treePointsValue);
                  calculatedHandler(true);
                }} 
                className="satellite-controls-button"
              >
                <img className='button-img' src={Calculate} alt="calculator" /> Calculate 
              </button>
            </div>

            <div className="legend">
              <div className="body">
                {vegetationAreas.map((area) => (
                  <div className="item" key={area.id}>
                    <div className="text">{area.text}</div>
                    <div className="colors">
                      <div className="color" style={{ backgroundColor: area.satelliteColor }}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        )}
      </SatelliteContainer>

    </>

  )
}


const SatelliteContainer = styled.div`
  .satellite-controls {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .satellite-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    jusitfy-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.34);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    maring-bottom: 20px;
    .buffer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      jsutify-content: center;
      gap: 5px;
      .buffer-add {
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
        padding: 5px 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: rgba(0, 0, 255, 1);
        font-weight: 700;
        span {
          font-size: 18px;
        }
      }
      .buffer-item {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 100%;
        .buffer-value-btns {
          position: relative;
          display: flex;
          justify-content: center;
          .buffer-input {
            width: 130px;
            border-radius: 5px;
            border: none;
            height: 25px;
            text-align: center;
          }
          .buffer-value-btn {
            position: absolute;
            width: 30px;
            height: 25px;
            padding: 5px;
            dissplay: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
            box-shadow: none;
          }
          .buffer-value-plus {
            right: 0;
          }
          .buffer-value-minus {
            left: 0;
          }
        }
        .buffer-input:focus {
          outline: none !important;
          border:1px solid rgba(0, 0, 255, 1);
          box-shadow: 0 0 10px #719ECE;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type=number] {
          -moz-appearance: textfield;
        }
        .remove-buffer-button {
          width: 20px;
          background-color: transparent;
          border: none;
          box-shadow: none;
          padding: 5px;
          cursor: pointer;
          &.disabled {
            img {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .satellite-controls button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 80%;
    .button-expanded {
      height: 100%;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      background-color: transparent;
      border: none;
      box-shadow: none;
      z-index: 100;
      img {
        transition: all 0.2s ease-in-out;
      }
    } 
    &.active {
      background-color: #007bff;
      color: #fff;
      &:hover {
        background-color: #268fff;
      }
    }
  }

  .button-img {
    width: 20px;
  }
`;

export default Satellite;